<template>
	<modal
		v-model="showDialog"
		:title="$t('transfer-call')"
		:sub-title="$t('transfer-info')"
		width="600"
		:type="'question'"
		:cancel-text="$t('cancel')"
		:confirm-text="$t('transfer-call')"
		:confirm-loading="confirmLoading"
		:confirm-disabled="confirmDisabled"
		@cancel="$emit('cancel')"
		@confirm="transferCall(group)"
	>
		<v-list rounded class="py-0 user-container mb-8">
			<v-list-item-group v-model="group" color="primary">
				<v-list-item v-for="userGroup of groups" :key="userGroup.id" :value="userGroup">
					<v-list-item-avatar color="initials">
						<span class="white--text">
							{{ userGroup.label[0] }}
						</span>
					</v-list-item-avatar>

					<v-list-item-content>
						<v-list-item-title>
							{{ `${userGroup.label} ${groupPhoneNumber(userGroup.phone)}` }}
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list-item-group>
		</v-list>
	</modal>
</template>

<script>
import { mapGetters } from "vuex";
import Modal from "@/components/common/Modal";
import { RequiredValidator } from "@/validators";
import { phoneNumberFormater } from "../../common/utils";

export default {
	name: "TransferCall",

	components: {
		Modal
	},
	
	props: {
		groups: {
			type: Array,
			default: () => []
		},

		open: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			group: null,
			loading: false,
			rules: {
				required: RequiredValidator
			},
			selectedUserGroupIndex: null,
			showDialog: this.open
		};
	},

	computed: {
		...mapGetters("conversation", ["currentParticipant"]),

		confirmDisabled() {
			return !this.group;
		},

		confirmLoading() {
			return this.loading;
		},

		groupPhoneNumber() {
			return (pn) => phoneNumberFormater(pn);
		}
	},

	watch: {
		showDialog: {
			deep: true,

			handler() {
				this.loading = false;
			}
		}
	},

	methods: {
		transferCall(group) {
			this.loading = true;
			this.$emit("confirm", { group: group });
		}
	}
};
</script>