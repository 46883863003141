import Vue from "vue";
import axios from "axios";
import store from "../../store";
import Message from "./message";
import ThreadList from "./thread-list";

export const THREAD_STATE_ACTIVE = "active";
export const THREAD_STATE_INACTIVE = "inactive";

export class ThreadUpdateOptions {
	/** @type {string} The current status "active" or "inactive" */
	status;

	/** @type {object} Thread attributes as key:value pairs */
	attributes;
}

export default class Thread {
	/** @type {string} */
	id;

	/** @type {string} */
	sid;

	/** @type {string} */
	friendlyName;

	/** @type {string} */
	organizationId;

	/** @type {string} */
	bindingId;

	/** @type {enum} */
	status;

	/** @type {object} */
	attributes = {};

	/** @type {Date} */
	createdAt;

	/** @type {Date} */
	updatedAt;

	/** @type {Message|null} The last sent/received message in this thread */
	lastMessage = null;

	constructor(threadData) {
		Object.assign(this, {
			...threadData,
			lastMessage: threadData.lastMessage && new Message({
				...threadData.lastMessage,
				_threadId: threadData.id
			})
		});
	}

	get state() {
		return {
			current: this.status
		};
	}

	get hasNotification() {
		return !!this.attributes.hasNotification;
	}

	get dateCreated() {
		return new Date(this.createdAt);
	}

	get dateUpdated() {
		return new Date(this.updatedAt);
	}

	/**
	 * @param {ThreadUpdateOptions} threadUpdateOptions The props to update
	 */
	async update(threadUpdateOptions) {
		return await axios.put(`${ThreadList.baseUrl}/${this.id}`, threadUpdateOptions);
	}

	async remove() {
		return await axios.delete(`${ThreadList.baseUrl}/${this.id}`);
	}

	/** @return {Message[]} Collection of messages within this thread */
	async getMessages() {
		const { data: threadMessages } = await axios.get(`${ThreadList.baseUrl}/${this.id}/messages`);

		const items = threadMessages.map((message) => new Message({ ...message, _threadId: this.id }));

		return {
			items
		};
	}

	/** @param {string} body The message body */
	async sendMessage(body) {
		try {
			const { data: sentMessage } = await axios.post(`${ThreadList.baseUrl}/${this.id}/messages`, {
				body,
				to: this.friendlyName
			});

			const message = new Message({ ...sentMessage, _threadId: this.id });

			this.lastMessage = message;

			// I don't like you being here but FCM will come & save you
			if (this.sid == store.getters["conversation/singleConversation"].sid) {
				store.commit("conversation/addSingleMessage", message);
				store.commit("conversation/setNewMessage", true);
			}

			return message;
		} catch (err) {
			console.error(err);
			throw err;
		}
	}

	/** @param {boolean} state Notification status on/true, off/false */
	async toggleNotifications(state) {
		Vue.set(this.attributes, "hasNotification", !!state);
		Vue.set(this.attributes, "isInformationMicrosite", false);

		await axios.put(`${ThreadList.baseUrl}/${this.id}`, {
			attributes: {
				hasNotification: !!state,
				isInformationMicrosite: false,
				isAppointmentMicrosite: false
			}
		});
	}

	/** Set thread state to "active" if it is archived */
	async setState(state) {
		if (![THREAD_STATE_ACTIVE, THREAD_STATE_INACTIVE].includes(state)) {
			throw new Error(`Invalid state ${state}`);
		}

		await axios.put(`${ThreadList.baseUrl}/${this.id}`, {
			status: state
		});

		this.status = state;
	}

	/**
	 * Create new thread instance from plain object data
	 * @param {object} threadData The plain object data
	 * @returns {Thread} New Thread object instance
	 */
	static from(threadData) {
		return new Thread(threadData);
	}
}
