import axios from "axios";
import { updateField, getField } from "vuex-map-fields";
const getDefaultState = () => ({
	allGroups: [],
	assignedGroups: []
});

export default {
	getters: {
		getField,
		allGroups: (state) => state.allGroups,
		assignedGroups: (state) => state.assignedGroups
	},
	mutations: {
		updateField,
		setAllGroups(state, allGroups) {
			state.allGroups = allGroups;
		},
		setAssignedGroups(state, assignedGroups) {
			state.assignedGroups = assignedGroups;
		}
	},
	actions: {
		async getGroups({ commit }) {
			var all = await axios.get("user-groups");
			var assigned = await axios.get("user-groups/belongs");
			const allGroups = all.data.sort((a, b) =>{ return a.label.localeCompare(b.label); });
			const assignedGroups = assigned.data.sort((a, b) =>{ return a.label.localeCompare(b.label); });
			
			commit("setAllGroups", allGroups);
			commit("setAssignedGroups", assignedGroups);
		}
	},
	namespaced: true,
	state: () => ({
		allGroups: [],
		assignedGroups: []
	})
};