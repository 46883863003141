/** @format */

import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

// Handle navigation duplication for router push (Globally)
const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((error) => {});
};

// Setup routes
const routes = [
	{
		path: "/onboarding",
		name: "onboarding",
		component: () => import(/* webpackChunkName: "onboarding" */ "@/views/Onboarding.vue"),
		meta: {
			menu: false
		}
	},
	{
		path: "/login",
		name: "login",
		component: () => import(/* webpackChunkName: "login" */ "@/views/auth/Login.vue"),
		meta: {
			nav: false,
			menu: false
		}
	},
	{
		path: "/signup",
		name: "signup",
		component: () => import(/* webpackChunkName: "signup" */ "@/views/auth/SignUp.vue"),
		meta: {
			nav: false,
			menu: false
		}
	},
	{
		path: "/forgot-password",
		name: "forgot-password",
		component: () => import(/* webpackChunkName: "forgot-password" */ "@/views/auth/ForgotPassword.vue"),
		meta: {
			nav: false,
			menu: false
		}
	},
	{
		path: "/reset-password",
		name: "reset-password",
		component: () => import(/* webpackChunkName: "reset-password" */ "@/views/auth/ResetPassword.vue"),
		meta: {
			nav: false,
			menu: false
		}
	},
	{
		path: "/password-forgot",
		component: () => import(/* webpackChunkName: "password-forgot" */ "@/views/auth/ForgotPassword.vue"),
		meta: {
			nav: false,
			menu: false
		}
	},
	{
		path: "/password-reset",
		component: () => import(/* webpackChunkName: "password-reset" */ "@/views/auth/ResetPassword.vue"),
		meta: {
			nav: false,
			menu: false
		}
	},
	{
		path: "/secure",
		component: () => import(/* webpackChunkName: "secure-file" */ "@/views/SecureFile.vue"),
		meta: {
			nav: false,
			menu: false
		}
	},
	{
		path: "/send-file",
		component: () => import(/* webpackChunkName: "send-file" */ "@/views/SendFile.vue"),
		meta: {
			nav: false,
			menu: false
		}
	},
	{
		path: "/configuration",
		name: "configuration",
		component: () => import(/* webpackChunkName: "organization" */ "@/views/Configuration.vue"),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: "/contacts",
		name: "contacts",
		component: () => import(/* webpackChunkName: "contacts" */ "@/components/my-contact/Contacts.vue"),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: "/contacts/:id",
		name: "contact-profile",
		component: () => import(/* webpackChunkName: "contact-profile" */ "@/components/my-contact/ClientProfile"),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: "/review",
		name: "review",
		component: () => import(/* webpackChunkName: "contact-profile" */ "@/components/review/Reviews"),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: "/contacts/documents/:id",
		name: "contact-documents",
		component: () =>
			import(/* webpackChunkName: "contact-profile" */ "@/components/my-contact/Document/Document"),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: "/contacts/documents/form",
		name: "form",
		component: () => import(/* webpackChunkName: "form" */ "@/components/form/Form.vue"),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: "/conversations",
		name: "conversations",
		component: () => import(/* webpackChunkName: "conversations" */ "@/views/Conversations.vue"),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: "/verification/error/:provider",
		component: () => import(/* webpackChunkName: "authorizationFailed" */ "@/views/AuthorizationFailed.vue"),
		meta: {
			requiresAuth: false,
			nav: false,
			menu: false
		}
	},
	{
		path: "/verification/success",
		component: () => import(/* webpackChunkName: "authorizationSuccess" */ "@/views/AuthorizationSuccess.vue"),
		meta: {
			requiresAuth: false,
			nav: false,
			menu: false
		}
	},
	{
		path: "*",
		redirect: "/login"
	}
];

// Create the router
const router = new VueRouter({
	base: process.env.BASE_URL,
	mode: "history",
	routes
});

// Router callbacks
router.beforeEach(async (to, _, next) => {
	store.commit(to.meta.nav === false ? "ui/disableNavigation" : "ui/enableNavigation");
	store.commit(to.meta.menu === false ? "ui/disableMenu" : "ui/enableMenu");

	next();
});

router.beforeEach(async (to, from, next) => {
	if (to.name !== "widget") {
		store.dispatch("login/activity");
	}

	if (to.matched.some((record) => record.meta.requiresAuth)) {
		if (!store.state.login.jwt) {
			next({
				path: "/login"
			});
		} else {
			next();
		}
	} else {
		next();
	}

	store.commit("alerts/clear");
});

router.beforeEach(async (to, from, next) => {
	// Don't fetch data if user is already disconnected
	if (from.name != "login" && store.state.login.connected) {
		await store.dispatch("clinic/getClinicData");
	}

	next();
});

export default router;
